import {
  createRouter,
  createWebHistory
} from 'vue-router';
import demoComponents from './demo-components';
import {
  loadingBar
} from 'heyui';

const routeConfig = {
  mode: 'history',
  // mode: 'hash',
  routes: [{
      path: '/login',
      name: 'Login',
      component: () => import('@components/login/index')
    },
    {
      path: '/',
      name: 'appframe',
      component: () => import('@components/app/app-frame'),
      children: [{
          path: '/',
          name: 'Home',
          component: () => import('@components/home/index'),
          meta: {
            title: '首页',
            icon: 'icon-home'
          }
        },

        {
          path: '/staff',
          name: 'Staff',
          component: () => import('@components/new-add/staff_management'),
          meta: {
            title: '管理工作人员',
            icon: 'icon-minus-cirlce'
          }
        },

        {
          path: '/marketing',
          name: 'Marketing',
          component: () => import('@components/new-add/marketing-management'),
          meta: {
            title: '营销活动',
            icon: 'icon-finance'
          },
        },

        {
          path: '/coupons',
          name: 'Coupons',
          component: () => import('@components/new-add/coupons-management'),
          meta: {
            title: '优惠券',
            icon: 'h-icon-link'
          },
        },

        {
          path: '/tenant',
          name: 'Tenant',
          component: () => import('@components/new-add/tenant-management'),
          meta: {
            title: '管理租户',
            icon: 'icon-minus-cirlce'
          }
        },

        {
          path: '/tenant-details',
          name: 'TenantDetails',
          component: () => import('@components/new-add/tenant-details-management'),
          meta: {
            title: '租户详情',
            icon: 'icon-minus-cirlce'
          }
        },

        {
          path: '/venue',
          name: 'Venue',
          component: () => import('@components/new-add/venue-management'),
          meta: {
            title: '管理场馆',
            icon: 'icon-minus-cirlce'
          }
        },

        {
          path: '/customer',
          name: 'Customer',
          component: () => import('@components/new-add/customer-management'),
          meta: {
            title: '顾客管理',
            icon: 'icon-Customer-Management'
          }
        },

        {
          path: '/wholehouseiot',
          name: 'WholeHouseIoT',
          component: () => import('@components/new-add/whole-house-iot-management'),
          meta: {
            title: '全屋物联',
            icon: 'h-icon-link'
          }
        },

        {
          path: '/customer-details',
          name: 'CustomerDetails',
          component: () => import('@components/new-add/customer-details-management'),
          meta: {
            title: '顾客详情',
            icon: 'icon-Customer-Management'
          }
        },
        {
          path: '/Commodity-management',
          name: 'Commodity',
          component: () => import('@components/view-components/Commodity-management'),
          meta: {
            title: '商品管理-设定',
            icon: 'icon-setting'
          }
        },
        {
          path: '/Commodity-grounding',
          name: 'Commoditygrounding',
          component: () => import('@components/view-components/Commodity-grounding'),
          meta: {
            title: '商品管理-上下架',
            icon: 'icon-setting-fill'
          }
        },
        {
          path: '/Venue-place',
          name: 'Venueplace',
          component: () => import('@components/view-components/Venue-place'),
          meta: {
            title: '场地管理',
            icon: 'icon-setting-fill'
          }
        },
        {
          path: '/Api-manage',
          name: 'Apimanage',
          component: () => import('@components/view-components/Api-manage'),
          meta: {
            title: 'API管理台',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/Data-report',
          name: 'Datareport',
          component: () => import('@components/view-components/Data-report'),
          meta: {
            title: '数据报表',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/Fin-manage',
          name: 'Finmanageorder',
          component: () => import('@components/view-components/Financial-management'),
          meta: {
            title: '销售订单',
            icon: 'icon-refund'
          }
        },
        {
          path: '/CancelCount-manage',
          name: 'CancelCountmanage',
          component: () => import('@components/view-components/Cancal-management'),
          meta: {
            title: '核销统计(次卡)',
            icon: 'icon-refund'
          }
        },
        {
          path: '/PointRecord-manage',
          name: 'PointRecordmanage',
          component: () => import('@components/view-components/PointRecord-management'),
          meta: {
            title: '积分记录',
            icon: 'icon-refund'
          }
        },
        {
          path: '/PointBalance-manage',
          name: 'PointBalancemanage',
          component: () => import('@components/view-components/PointBalance-management'),
          meta: {
            title: '积分余额',
            icon: 'icon-refund'
          }
        },
        {
          path: '/ActionLibrary-manage',
          name: 'ActionLibrarymanage',
          component: () => import('@components/view-components/ActionLibrary-management'),
          meta: {
            title: '尚体动作库',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Holiday-settings',
          name: 'Holidaysettings',
          component: () => import('@components/view-components/Holiday-settings'),
          meta: {
            title: '节假日设置',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Venue-reservation',
          name: 'VenueReservation',
          component: () => import('@components/view-components/Venue-reservation'),
          meta: {
            title: '场地预约',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Fin-manageclerk',
          name: 'Finmanageorderclerk',
          component: () => import('@components/view-components/Financial-managementclerk'),
          meta: {
            title: '销售订单',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Fin-managepay',
          name: 'Finmanagepay',
          component: () => import('@components/view-components/Financial-managementpay'),
          meta: {
            title: '支付交易',
            icon: 'icon-refund'
          }
        },
        {
          path: '/system-error',
          name: 'SystemError',
          component: () => import('@components/error-pages/500'),
          meta: {
            title: '系统错误'
          }
        },
        {
          path: '/permission-error',
          name: 'PermissionError',
          component: () => import('@components/error-pages/403'),
          meta: {
            title: '权限错误'
          }
        },
        {
          path: '/notfound-error',
          name: 'NotfoundError',
          component: () => import('@components/error-pages/404'),
          meta: {
            title: '页面找不到'
          }
        },
        {
          path: '/QuestionNaire',
          name: 'QuestionNaire',
          component: () => import('@components/exrx/questionnaire'),

          meta: {
            title: ' 问卷-答题',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-task',
          name: 'ExrxTask',
          component: () => import('@components/exrx/exrx-task'),
          meta: {
            title: '运动处方管理',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-adjust',
          name: 'ExrxAdjust',
          component: () => import('@components/exrx/exrx-adjust'),
          meta: {
            title: '手动调整',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-hand',
          name: 'ExrxHand',
          component: () => import('@components/exrx/exrx-hand'),
          meta: {
            title: '人工处方',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-config-factor-list',
          name: 'ExrxConfigFactorList',
          component: () => import('@components/exrx-config/factor-list'),
          meta: {
            title: '运动处方管理',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-config-stage-list',
          name: 'ExrxConfigStageList',
          component: () => import('@components/exrx-config/stage-list'),
          meta: {
            title: '运动处方管理',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-config-intensity-list',
          name: 'ExrxConfigIntensityList',
          component: () => import('@components/exrx-config/intensity-list'),
          meta: {
            title: '运动处方管理',
            icon: 'icon-refund'
          }
        },
        {
          path: '/exrx-detail',
          name: 'ExerDetail',
          component: () => import('@components/exrx/exrx-detail'),
          meta: {
            title: '运动处方详情',
            icon: 'icon-refund'
          }
        },
        {
          path: '/questionnaire-detail',
          name: 'QuestionnaireDetail',
          component: () => import('@components/exrx/questionnaire-detail'),
          meta: {
            title: '问卷详情',
            icon: 'icon-refund'
          }
        },

        {
          path: '/exrx-detail',
          name: 'ExerDetail',
          component: () => import('@components/exrx/exrx-detail'),
          meta: {
            title: '运动处方详情',
            icon: 'icon-refund'
          }
        },

        {
          path: '/measdata_details',
          name: 'MeasdataDetails',
          component: () => import('@components/measdata/measdata_details'),
          meta: {
            title: ' 体测数据显示',
            icon: 'icon-refund'
          }
        }, {
          path: '/exerdata_details',
          name: 'ExerdataDetails',
          component: () => import('@components/new-add/exerdata_details'),
          meta: {
            title: ' 运动数据显示',
            icon: 'icon-cog'
          }
        },
        {
          path: '/exerdata-create',
          name: 'ExerdataCreate',
          component: () => import('@components/new-add/exerdata-create'),
          meta: {
            title: ' 添加运动数据',
            icon: 'icon-cog'
          },
        },
        {
          path: '/hospital-exrx_details',
          name: 'HospitalExrxDetails',
          component: () => import('@components/new-add/hospital-exrx_details'),
          meta: {
            title: '三方处方详情',
            icon: 'icon-cog'
          }
        },
        {
          path: '/daily',
          name: 'Daily',
          component: () => import('@components/new-add/daily-management'),
          meta: {
            title: '运营报表',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/dailysign',
          name: 'Dailysign',
          component: () => import('@components/new-add/Daily-sign'),
          meta: {
            title: '会员签到',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/dailydevice',
          name: 'Dailydevice',
          component: () => import('@components/new-add/Daily-device'),
          meta: {
            title: '设备使用',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/dailystate',
          name: 'Dailystate',
          component: () => import('@components/new-add/Daily-state'),
          meta: {
            title: '政府履约',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/statistics',
          name: 'Statistics',
          component: () => import('@components/new-add/classroom-statistics'),
          meta: {
            title: '团课报表',
            icon: 'icon-order-for-goods'
          }
        },
        {
          path: '/at-venue-customer',
          name: 'AtVenueCustomer',
          component: () => import('@components/new-add/at-venue-customer-management'),
          meta: {
            title: '在场顾客',
            icon: 'icon-bell'
          }
        },

        {
          path: '/sales-date',
          name: 'SalesData',
          component: () => import('@components/new-add/sales-data-management'),
          meta: {
            title: '销售数据',
            icon: 'icon-bell'
          }
        },

        {
          path: '/venue-overview',
          name: 'VenueOverview',
          component: () => import('@components/new-add/venue-overview-management'),
          meta: {
            title: '场馆概况',
            icon: 'icon-bell'
          }
        },
        {
          path: '/Appointment-record',
          name: 'Appointmentrecord',
          component: () => import('@components/view-components/Appointment-record'),
          meta: {
            title: '课程管理',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Class-record',
          name: 'Classrecord',
          component: () => import('@components/view-components/Class-record'),
          meta: {
            title: '上课记录',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Course-scheduling',
          name: 'Coursescheduling',
          component: () => import('@components/view-components/Course-scheduling'),
          meta: {
            title: '排课管理',
            icon: 'icon-refund'
          }
        },
        {
          path: '/Course-record',
          name: 'Courserecord',
          component: () => import('@components/view-components/Course-record'),
          meta: {
            title: '上课记录',
            icon: 'icon-refund'
          }
        },
        ...demoComponents,
        {
          path: '/:pathMatch(.*)*',
          name: 'CommonNotfoundError',
          component: () => import('@components/error-pages/404'),
          meta: {
            title: '页面找不到'
          }
        }, {
          path: '/point-record',
          name: 'PointRecord',
          component: () => import('@components/view-components/PointRecord-management'),
          meta: {
            title: '积分记录',
            icon: 'icon-refund'
          }
        }, {
          path: '/point-balance',
          name: 'PointBalance',
          component: () => import('@components/view-components/PointBalance-management'),
          meta: {
            title: '积分余额', 
            icon: 'icon-refund'
          }
        },
        {
          path: '/lehuodaily',
          name: 'lehuodaily',
          component: () => import('@components/new-add/lehuodaily'),
          meta: {
            title: '乐活日报', 
            icon: 'icon-refund'
          }
        },
        {
          path: '/lehuoMonthly',
          name: 'lehuoMonthly',
          component: () => import('@components/new-add/lehuoMonthly'),
          meta: {
            title: '乐活月报', 
            icon: 'icon-refund'
          }
        },
        {
          path: '/condominium',
          name: 'condominium',
          component: () => import('@components/new-add/condominium'),
          meta: {
            title: '三高共管', 
            icon: 'icon-refund'
          }
        },
        {
          path: '/Sportsranking',
          name: 'Sportsranking',
          component: () => import('@components/new-add/Sports-ranking'),
          meta: {
            title: '运动排名', 
            icon: 'icon-refund'
          }
        },
        {
          path: '/PrepaidOrder',
          name: 'PrepaidOrder',
          component: () => import('@components/view-components/Prepaid-order'),
          meta: {
            title: '预付卡订单', 
            icon: 'icon-refund'
          }
        }
      ]
    }
  ]
};

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory('/dist/'),
  routes: routeConfig.routes
});
// history: createWebHistory("/path/"),


let isFirstRouter = true;

router.beforeEach((to, from, next) => {
  loadingBar.start();
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' - 管理应用';
  } else {
    document.title = '管理系统';
  }
  isFirstRouter = false;
  next();
});

router.afterEach(() => {
  loadingBar.success();
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
  let layoutContent = document.querySelector('.h-layout-content');
  if (layoutContent) {
    layoutContent.scrollTop = 0;
  }
  // baidu 统计，如果有自己的统计，请至index.html修改至自己的埋点
  // if (window._hmt) {
  //   window._hmt.push(['_trackPageview', window.location.pathname]);
  // }
});

export default router;
